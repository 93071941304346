<template>
  <v-dialog max-width="950" v-model="dialog" persistent>
    <v-sheet elevation="2" class="pa-5" height="100%" overflow="scroll">
      <v-form v-model="valid" ref="form" @submit.prevent="createCreditNote">
        <v-container>
          <v-row class="mb-8">
            <v-col md="12" sm="12">
              <!--Close Button-->
              <v-btn class="float-right" icon small @click="closeModal()"
                ><v-icon>mdi-close</v-icon></v-btn
              >
              <h2>
                New
                {{ !uninvoiced_order ? "Credit Note" : "Down Payment Refund" }}
              </h2>
            </v-col>
          </v-row>
          <!--Error message-->
          <v-row v-if="show_error">
            <v-col cols="12">
              <v-alert text dense type="error">
                <p v-for="item in error_messages" :key="item.name">
                  {{ item.text }}
                </p>
              </v-alert>
            </v-col>
          </v-row>
          <!--Form-->
          <v-row>
            <v-col lg="3" md="3" sm="3">
              <!--Credit Note #-->
              <v-text-field
                outlined
                dense
                readonly
                v-model="credit_note_number"
                label="Credit Note #"
                v-if="!uninvoiced_order"
              ></v-text-field>
              <!--Down Payment Number #-->
              <v-text-field
                outlined
                dense
                readonly
                v-model="down_payment_number"
                label="Down Payment Refund #"
                v-else
              ></v-text-field>
            </v-col>
            <!--Date Picker-->
            <v-col lg="3" md="3" sm="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="true"
                :return-value.sync="date"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="[rules.required('date')]"
                    :value="credit_note_date"
                    label="Date Issued"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    clearable
                    color="#3d2cdd"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="credit_note_date"
                  no-title
                  scrollable
                  color="#3d2cdd"
                  show-adjacent-months
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <!--Select Customer-->
            <v-col lg="3" md="3" sm="3">
              <v-autocomplete
                v-model="customer"
                :return-object="true"
                item-text="customer_name"
                label="Customer"
                :items="customers"
                outlined
                dense
                clearable
                @click.clear="
                  invoice = null;
                  cancelled_order = null;
                  line_items = [];
                "
                :readonly="!!customer"
                @change="
                  getInvoices();
                  getSalesOrders();
                "
                :rules="[rules.required('customer')]"
              ></v-autocomplete>
            </v-col>
            <!--Invoice-->
            <v-col lg="3" md="3" sm="3" v-if="!uninvoiced_order">
              <v-autocomplete
                v-model="invoice"
                item-text="invoice_number"
                :return-object="true"
                label="Invoice Ref"
                :items="invoices"
                outlined
                dense
                clearable
                :disabled="!customer"
                @change="getInvoiceLineItems()"
                :rules="[rules.required('invoice')]"
                @click.clear="line_items = []"
              ></v-autocomplete>
            </v-col>
            <!--Cancelled pro-formas-->
            <v-col lg="3" md="3" sm="3" v-else>
              <v-autocomplete
                v-model="cancelled_order"
                item-text="order_number"
                :return-object="true"
                label="Order Ref"
                :items="sales_orders"
                outlined
                dense
                clearable
                :disabled="!customer"
                @change="getProFormaLineItems()"
                :rules="[rules.required('order')]"
                @click.clear="line_items = []"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!--Uninvoiced && Cancelled order-->
          <v-row dense>
            <v-col lg="4" md="4" sm="4">
              <v-checkbox
                v-model="uninvoiced_order"
                color="primary"
                label="Down payment refund"
                @change="
                  getInvoices();
                  getSalesOrders();
                  invoice = null;
                  cancelled_order = null;
                  line_items = [];
                  show_error = false;
                  error_messages = [];
                "
              ></v-checkbox>
            </v-col>
          </v-row>
          <!--Line Item Table-->
          <v-row v-if="line_items.length !== 0">
            <v-col cols="12">
              <v-data-table
                :items="line_items"
                :headers="line_item_headers"
                hide-default-footer
              >
                <!--Format prices and line item totals-->
                <template v-slot:item.product_price="{ item }">
                  {{ formatAsCurrency("R", item.product_price) }}
                </template>
                <template v-slot:item.line_item_amount="{ item }">
                  {{ formatAsCurrency("R", item.line_item_amount) }}
                </template>
                <!--Credited Amount-->
                <template v-slot:item.item_credited_amount="{ item }">
                  <v-text-field
                    v-model.number="item.item_credited_amount"
                    type="number"
                    prefix="R"
                    min="0"
                    :max="item.line_item_amount"
                    step="0.01"
                    outlined
                    dense
                    class="mt-3"
                    :rules="[
                      rules.maxValueRule(item.line_item_amount),
                      rules.minValueRule(),
                    ]"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!--Alert message-->
          <v-row v-else>
            <v-col cols="12">
              <v-alert type="info" text
                >Select a Customer and an
                {{ !uninvoiced_order ? "Invoice" : "Sales Order" }} to
                continue.</v-alert
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <!--Totals display-->
          <v-row>
            <v-col
              lg="4"
              md="4"
              sm="4"
              offset-lg="8"
              offset-md="8"
              offset-sm="8"
            >
              <!--Total Ex VAT-->
              <span>
                <span>Subtotal</span>
                <span class="ml-10 float-right">{{
                  formatAsCurrency("R", amountExVAT)
                }}</span>
              </span>
              <br />
              <!--VAT amount-->
              <span>
                <span>VAT</span>
                <span class="ml-10 float-right">{{
                  formatAsCurrency("R", vatAmountCredited)
                }}</span>
              </span>
              <br />
              <!--Total-->
              <span>
                <h3 class="mt-3">
                  Total
                  <span class="ml-10 float-right">
                    {{ formatAsCurrency("R", totalCreditedAmount) }}
                  </span>
                </h3>
              </span>
              <br />
            </v-col>
          </v-row>
          <!--Buttons-->
          <v-row>
            <v-col cols="12">
              <!--Submit-->
              <v-btn
                color="primary"
                class="float-right"
                @click="createCreditNote()"
                :loading="loading"
                ><v-icon small left>mdi-check</v-icon>Create
                {{
                  !uninvoiced_order ? "Credit Note" : "Down Payment Refund"
                }}</v-btn
              >
              <!--Cancel-->
              <v-btn text class="float-right mr-2" @click="closeModal()"
                ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-sheet>
  </v-dialog>
</template>
<script>
import db from "../../../../components/firebaseInit";
import firebase from "firebase";
import { formatAsCurrency } from "../../../../composables/external";
import mixinCompanyProfile from "../../../../globalActions/mixin_CompanyProfile";
import { showSnackbar } from "../../../../globalActions";
export default {
  name: "NewCreditNoteModal",
  props: ["dialog"],
  mixins: [mixinCompanyProfile],
  data() {
    return {
      valid: false,
      loading: false,
      // Pro-forma related data
      uninvoiced_order: false,
      cancelled_order: null,
      sales_orders: [],
      //
      credit_note_number: null,
      down_payment_number: null,
      credit_note_date: null,
      customers: [],
      customer: null,
      invoices: [],
      invoice: null,
      line_items: [],
      date: null,
      menu: false,
      show_error: false,
      error_messages: [],
      line_item_headers: [
        { text: "Item", value: "product_name", align: "start" },
        { text: "QTY", value: "item_qty" },
        { text: "Price", value: "product_price", align: "right" },
        { text: "Line Amount", value: "line_item_amount", align: "right" },
        {
          text: "Amount To Credit",
          value: "item_credited_amount",
          align: "right",
        },
      ],
      // Rules
      rules: {
        maxValueRule: (max) => {
          return (v) => (v || "") <= max || "Amount can't exceed line total.";
        },
        minValueRule() {
          return (v) => (v || "") >= 0 || "Amount must be greater than 0.";
        },
        required: (field) => {
          return (v) => !!v || `You must select a ${field}.`;
        },
      },
    };
  },
  computed: {
    vatRate() {
      if (!this.invoice) return 0;
      if (!this.company_is_vattable) return 0;
      if (this.invoice.invoice_issue_date < this.VAT_start_date) return 0;
      return 15;
      // If the invoice date is older than VAT start period
    },
    totalCreditedAmount() {
      return this.line_items.reduce((total, item) => {
        return total + item.item_credited_amount;
      }, 0);
    },
    vatAmountCredited() {
      return (this.totalCreditedAmount * this.vatRate) / (100 + this.vatRate);
    },
    amountExVAT() {
      return this.totalCreditedAmount - this.vatAmountCredited;
    },
  },
  created() {
    this.getCreditNoteCount();
    this.getDPRCount();
    this.getCustomers();
  },
  methods: {
    formatAsCurrency,
    //
    closeModal() {
      this.$emit("closeDialog");
      this.invoice = null;
      this.customer = null;
      this.line_items = [];
      this.credit_note_date = null;
      this.show_error = false;
      this.error_messages = [];
      this.uninvoiced_order = false;
    },
    // Format Credit Note #
    getCreditNoteCount() {
      db.doc("credit_note_counter/Gq32IoI66ddWOKd8l5IE").onSnapshot((doc) => {
        const count = doc.data().credit_note_count;
        this.credit_note_number = `CN-${
          count < 1000 ? String("00000" + count).slice(-4) : count
        }`;
      });
    },
    getDPRCount() {
      db.doc("credit_note_counter/Gq32IoI66ddWOKd8l5IE").onSnapshot((doc) => {
        const count = doc.data().down_payment_count;
        this.down_payment_number = `DPR-${
          count < 1000 ? String("00000" + count).slice(-4) : count
        }`;
      });
    },
    // Get customers from database
    async getCustomers() {
      const docRef = db.collection("customers").orderBy("customer_name");
      try {
        const snapshot = docRef.get();
        this.customers = (await snapshot).docs.map((doc) => ({
          ...doc.data(),
        }));
      } catch (error) {
        return new Error(error);
      }
    },
    //
    async getInvoices() {
      if (this.uninvoiced_order) return;
      // Return early if customer field has been reset
      if (!this.customer) {
        this.invoices = [];
        this.invoice = null;
        return;
      }
      const docRef = db
        .collection("invoices")
        .where("customer.customer_id", "==", this.customer.customer_id);
      try {
        const snapshot = await docRef.get();
        this.invoices = snapshot.docs.map((doc) => ({ ...doc.data() }));
      } catch (error) {
        return new Error(error);
      }
    },
    //
    async getSalesOrders() {
      if (!this.uninvoiced_order) return;
      // Return early if customer field has been reset
      if (!this.customer) {
        this.sales_orders = [];
        this.cancelled_order = null;
        return;
      }
      const docRef = db
        .collection("sales_orders")
        .where("customer.customer_id", "==", this.customer.customer_id)
        .where("order_status", "==", "Cancelled");
      try {
        const snapshot = await docRef.get();
        this.sales_orders = snapshot.docs.map((doc) => ({ ...doc.data() }));
      } catch (error) {
        return new Error(error);
      }
    },
    //
    async getInvoiceLineItems() {
      // Return early if no invoice is selected or if customer has been reset
      if (!this.invoice) return (this.line_items = []);
      // Get discount rate from sales order
      const docRef = db.doc(`sales_orders/${this.invoice.sales_order_id}`);
      try {
        const doc = await docRef.get();
        const discountRate =
          doc.data().discountRate === 0 ? 1 : doc.data().discountRate;
        //
        this.line_items = this.invoice.line_items.map((x) => {
          // calculate discount price
          const price =
            discountRate === 1
              ? x.product_price
              : x.product_price - (x.product_price / 100) * discountRate;
          return {
            product_name: x.product_name,
            item_qty: x.item_qty,
            product_price: price,
            line_item_amount: price * x.item_qty,
            item_credited_amount: x.item_credited_amount
              ? x.item_credited_amount
              : 0,
          };
        });
        // Add delivery fee
        if (this.invoice.order_delivery_fee === 0) return;
        this.line_items.push({
          product_name: "Delivery Fee",
          item_qty: 1,
          product_price: this.invoice.order_delivery_fee,
          line_item_amount: this.invoice.order_delivery_fee,
          item_credited_amount: this.delivery_credited_amount
            ? this.delivery_credited_amount
            : 0,
        });
        //
      } catch (error) {
        return new Error(error);
      }
    },
    //
    getProFormaLineItems() {
      // Return early if no invoice is selected or if customer has been reset
      if (!this.cancelled_order) return (this.line_items = []);
      this.line_items = this.cancelled_order.line_items.map((x) => {
        // calculate discount price
        const price =
          this.cancelled_order.discountRate === 0
            ? x.product_price
            : x.product_price -
              (x.product_price / 100) * this.cancelled_order.discountRate;
        return {
          product_name: x.product_name,
          item_qty: x.item_qty,
          product_price: price,
          line_item_amount: price * x.item_qty,
          item_credited_amount: x.item_credited_amount
            ? x.item_credited_amount
            : 0,
        };
      });
      // Add delivery fee
      if (this.cancelled_order.order_delivery_fee === 0) return;
      this.line_items.push({
        product_name: "Delivery Fee",
        item_qty: 1,
        product_price: this.cancelled_order.order_delivery_fee,
        line_item_amount: this.cancelled_order.order_delivery_fee,
        item_credited_amount: this.delivery_credited_amount
          ? this.delivery_credited_amount
          : 0,
      });
    },
    //
    async createCreditNote() {
      // 1) Validate form
      const validateForm = () => {
        // 1.1 Missing mandatory fields
        const validArray = [];
        validArray.push(this.$refs.form.validate());
        if (validArray.includes(false)) {
          this.valid = false;
          // Add error message
          if (this.error_messages.some((x) => x.name === "mandatory")) return;
          this.error_messages.push({
            name: "mandatory",
            text: "There are mandatory fields that need to be completed or amended.",
          });
        } else {
          // Remove error message
          this.error_messages = this.error_messages.filter(
            (x) => x.name !== "mandatory"
          );
        }
        // 1.2 Total amount credited !== 0
        if (this.totalCreditedAmount === 0) {
          this.valid = false;
          // Add error message
          if (this.error_messages.some((x) => x.name === "no_amount")) return;
          this.error_messages.push({
            name: "no_amount",
            text: "The 'Amount to Credit' must be greater than 0.",
          });
        } else {
          // Remove error message
          this.error_messages = this.error_messages.filter(
            (x) => x.name !== "no_amount"
          );
        }
        // 1.3 Total amount credited can't exceed total of all line items
        // MAKE provision for either invoices or cancelled orders
        if (
          !this.uninvoiced_order
            ? !!this.invoice.invoice_total &&
              this.totalCreditedAmount > this.invoice.invoice_total
            : !!this.cancelled_order.order_value &&
              this.totalCreditedAmount > this.cancelled_order.order_value
        ) {
          this.valid = false;
          // Add error message
          if (
            this.error_messages.some((x) => x.name === "exceeds_invoice_total")
          )
            return;
          this.error_messages.push({
            name: "exceeds_invoice_total",
            text: `The 'Amount to Credit' can't be greater than the total amount ${
              !this.uninvoiced_order ? "invoiced" : "of the sales order"
            }.`,
          });
        } else {
          // Remove error message
          this.error_messages = this.error_messages.filter(
            (x) => x.name !== "exceeds_invoice_total"
          );
        }
      };
      validateForm();
      if (!this.valid) return (this.show_error = true);
      this.loading = true;
      this.error_messages = [];
      this.show_error = false;
      // 2) Save Credit Note to database
      const batch = db.batch();
      const creditNoteRef = db.collection("customer_credit_notes").doc();
      const saveToDatabase = () => {
        batch.set(creditNoteRef, {
          credit_note_id: creditNoteRef.id,
          credit_note_number: !this.uninvoiced_order
            ? this.credit_note_number
            : this.down_payment_number,
          credit_note_date: this.credit_note_date,
          customer: this.customer,
          [`${!this.uninvoiced_order ? "invoice" : "sales_order"}`]: !this
            .uninvoiced_order
            ? this.invoice
            : this.cancelled_order,
          line_items: this.line_items,
          total_credited_amount: this.totalCreditedAmount,
          vat_amount_credited: this.vatAmountCredited,
          amount_credited_ex_vat: this.amountExVAT,
          credit_note_amount_refunded: 0,
        });
      };
      saveToDatabase();
      // 3) Update ledger totals
      const updateLedgers = () => {
        // MAKE PROVISION FOR INVOICES AND CANCELLED PRO-FORMAS
        // Scenario = Invoiced
        if (!this.uninvoiced_order) {
          // 3.1 - Increase Accounts Receivable Contra
          const accountRef = db.doc(
            `accounting_totals/${this.credit_note_date.slice(
              0,
              7
            )}/ledger_accounts/HXGIZu7HoqPCgVev56Ee`
          );
          batch.update(accountRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              this.amountExVAT
            ),
          });
          // 3.2 - Increase Customer Credit Notes ledger
          const ledgerRef = db.doc(
            `accounting_totals/${this.credit_note_date.slice(
              0,
              7
            )}/ledger_accounts/1yPoJPxmbqLVcgiL0uLQ`
          );
          batch.update(ledgerRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              this.totalCreditedAmount
            ),
          });
          // 3.3 - Increase VAT ledgers
          const vatRef = db.doc(
            `accounting_totals/${this.credit_note_date.slice(
              0,
              7
            )}/ledger_accounts/IqBevsLG9aOsTrFon8Ef`
          );
          batch.update(vatRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              this.vatAmountCredited
            ),
          });
        }
        // Scenario = Cancelled Pro-Forma
        if (this.uninvoiced_order) {
          // 3.1 - Increase Customer Downpayment Refunds Contra
          const ledgerRef = db.doc(
            `accounting_totals/${this.credit_note_date.slice(
              0,
              7
            )}/ledger_accounts/BHlsHkQ5SXkPDSSK5Vh2`
          );
          batch.update(ledgerRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              this.totalCreditedAmount
            ),
          });
        }
      };
      updateLedgers();
      // 4) Increment CN counter
      const incrementCounter = () => {
        const countRef = db.doc("credit_note_counter/Gq32IoI66ddWOKd8l5IE");
        batch.update(countRef, {
          [`${
            !this.uninvoiced_order ? "credit_note_count" : "down_payment_count"
          }`]: firebase.firestore.FieldValue.increment(1),
        });
      };
      incrementCounter();
      // 5) Link credit note to invoice
      const linkToInvoice = () => {
        const invoiceRef = db.doc(`invoices/${this.invoice.invoice_id}`);
        const invoiceCreditNotes = [
          {
            credit_note_id: creditNoteRef.id,
            credit_note_number: this.credit_note_number,
            total_credited_amount: this.totalCreditedAmount,
          },
        ];
        const newAmountDue =
          this.invoice.invoice_amount_due === 0
            ? this.invoice.invoice_amount_due
            : this.invoice.invoice_amount_due - this.totalCreditedAmount;
        // check if invoice already has credit notes
        batch.update(invoiceRef, {
          credit_notes:
            this.invoice.credit_notes && this.invoice.credit_notes.length > 0
              ? this.invoice.credit_notes.concat(invoiceCreditNotes)
              : invoiceCreditNotes,
          invoice_amount_due: newAmountDue,
        });
      };
      //
      const linkToProForma = () => {
        const orderRef = db.doc(
          `sales_orders/${this.cancelled_order.order_id}`
        );
        const invoiceCreditNotes = [
          {
            credit_note_id: creditNoteRef.id,
            credit_note_number: this.credit_note_number,
            total_credited_amount: this.totalCreditedAmount,
          },
        ];
        // check if invoice already has credit notes
        batch.update(orderRef, {
          credit_notes:
            this.cancelled_order.credit_notes &&
            this.cancelled_order.credit_notes.length > 0
              ? this.cancelled_order.credit_notes.concat(invoiceCreditNotes)
              : invoiceCreditNotes,
        });
      };
      // Call the correct function based on the scenario
      !this.uninvoiced_order ? linkToInvoice() : linkToProForma();

      // TODO
      // 7) Update credited amounts to line items
      try {
        await batch.commit();
        showSnackbar("Credit Note Created Successfully!");
        this.loading = false;
        this.closeModal();
      } catch (error) {
        return new Error(error);
      }
    },
  },
};
</script>